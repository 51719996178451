import React from 'react';
import { Typography, Tooltip, Form, Input, Button, Row, Col, Rate, Space, Radio, Checkbox, Result } from 'antd';
import { InfoCircleOutlined, SmileOutlined, StarOutlined, FrownOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Title } = Typography;
const Global = props => {
    const [form1] = Form.useForm();

    //* Отправка формы
    async function user_send(v) {
        for( let value in v) {
            if(v[value] instanceof Array) {
               v[value] = v[value].join('!=@=!');
            }
        };
        v['hash'] = props.state.hash;
        v['method'] = 'user_send';
        await props.api(v,true);
    }


    return (
        <React.Fragment>
            <div className="headerfull">
                <div className="logoheader">
                </div>
            </div>
            <div className="mainbody">
                <div className="title_top">
                    Для прохождения опроса нужно иметь приглашение
                </div>
            </div>
        </React.Fragment>
    )
};

export default Global;