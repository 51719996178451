import React from 'react';
import {  } from 'antd';
import {  } from '@ant-design/icons';

const Global = props => {
    
    return (
        null
    )
};

export default Global;